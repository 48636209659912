import React from "react";
import clsx from "clsx";
import { Heading, IconCopy } from "@molecules";
import { Container } from "@atoms";
import AccordionPattern from "@svg/AccordionPattern";

const IconCopyGrid = ({
  heading,
  description,
  columns,
  className: _className,
}) => {
  return (
    <section className={clsx("relative", _className)}>
      <Container>
        <div className="relative overflow-hidden rounded-3xl bg-purple p-12 text-white">
          <Heading
            className="relative z-10"
            heading={heading}
            description={description}
            size="longH3"
            inverse
          />
          <div
            className={clsx(
              "grid grid-cols-1 gap-10 sm:grid-cols-2 md:grid-cols-3 md:px-4 lg:px-12",
              { "mt-8 sm:mt-16": heading }
            )}
          >
            {columns?.map(column => {
              return <IconCopy inverse {...column} />;
            })}
          </div>
          <AccordionPattern className="absolute -left-10 -top-20 z-0 w-80 -scale-y-100 transform text-teal sm:w-[27.5rem]" />
        </div>
      </Container>
    </section>
  );
};

export default IconCopyGrid;
