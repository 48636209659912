import React from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import getAnimation from "@utils/getAnimation";

const AccordionPattern = ({ fitHeight, className: _className }) => {
  const draw = getAnimation("draw");
  return (
    <span
      className={clsx("icon block", _className, {
        "icon--fit-height": fitHeight,
      })}
    >
      <m.svg
        width="588"
        height="493"
        viewBox="0 0 588 493"
        initial="hidden"
        whileInView="visible"
        className="stroke-2 opacity-40 sm:stroke-1 sm:opacity-100"
        viewport={{
          once: true,
        }}
      >
        <m.path
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          d="M307.797 342.627C307.852 347.834 312.089 352.026 317.297 352.026H480.323C486.758 352.026 491.994 346.846 492.062 340.411C492.539 295.292 456.095 258.46 410.972 258.46H330.797C317.644 258.46 307.021 269.197 307.16 282.349L307.797 342.627Z"
        />
        <m.path
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          d="M203.601 321.024C210.137 321.024 215.436 315.725 215.436 309.189V235.407C215.436 230.16 211.182 225.907 205.936 225.907H133.906C127.259 225.907 121.869 231.296 121.869 237.944V239.292C121.869 284.431 158.462 321.024 203.601 321.024Z"
        />
        <m.path
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          d="M336.316 321.024C341.475 321.024 345.658 316.841 345.658 311.681V268.418C345.658 220.111 306.497 180.949 258.19 180.949C254.822 180.949 252.092 183.679 252.092 187.047V311.524C252.092 316.77 256.345 321.024 261.592 321.024H336.316Z"
        />
        <m.path
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          d="M184.432 380.093C184.432 381.718 183.115 383.034 181.491 383.034H131.826C83.5186 383.034 44.3576 343.873 44.3576 295.566C44.3576 292.198 47.0876 289.468 50.4552 289.468H174.932C180.178 289.468 184.432 293.721 184.432 298.968V380.093Z"
        />
        <m.path
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          d="M95.6162 87.9685C95.6162 39.661 56.4552 0.5 8.14773 0.5L6.7056 0.5C3.27824 0.5 0.499809 3.27843 0.499809 6.7058L0.499809 84.5661C0.499809 89.8128 4.75311 94.0661 9.99981 94.0661L89.5186 94.0661C92.8862 94.0661 95.6162 91.3361 95.6162 87.9685Z"
        />
        <m.path
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          d="M146.59 219.968C146.59 171.661 107.429 132.5 59.1214 132.5H57.6792C54.2519 132.5 51.4734 135.278 51.4734 138.706V216.566C51.4734 221.813 55.7267 226.066 60.9734 226.066H140.492C143.86 226.066 146.59 223.336 146.59 219.968Z"
        />
        <m.path
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          d="M587.5 295.566C587.5 343.873 548.339 383.034 500.032 383.034H498.589C495.162 383.034 492.384 380.256 492.384 376.828V298.968C492.384 293.721 496.637 289.468 501.884 289.468H581.402C584.77 289.468 587.5 292.198 587.5 295.566Z"
        />
        <m.path
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          d="M308.453 329.972C308.453 324.726 304.2 320.472 298.953 320.472H224.387C219.14 320.472 214.887 324.726 214.887 329.972V404.538C214.887 409.785 219.14 414.038 224.387 414.038H298.953C304.2 414.038 308.453 409.785 308.453 404.538V329.972Z"
        />
        <m.path
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          d="M408.143 351.998C402.984 351.998 398.801 356.181 398.801 361.341V404.604C398.801 452.911 437.962 492.072 486.269 492.072C489.637 492.072 492.367 489.342 492.367 485.975V361.498C492.367 356.251 488.114 351.998 482.867 351.998H408.143Z"
        />
        <m.path
          stroke="currentColor"
          fill="transparent"
          variants={draw}
          d="M86.5656 319.472C91.8123 319.472 96.0656 315.219 96.0656 309.972L96.0656 102.231C96.0656 97.7588 92.4404 94.1337 87.9686 94.1337C40.7653 94.1337 2.49942 132.4 2.49942 179.603L2.49942 309.972C2.49942 315.219 6.75271 319.472 11.9994 319.472H86.5656Z"
        />
      </m.svg>
    </span>
  );
};

AccordionPattern.defaultProps = {};

export default AccordionPattern;
